import React from 'react';
import {ReactComponent as FooterLogo} from '../footer-logo.svg';
import {LinkedInLogo} from './WieWeZijn'
import { navigation } from './Nav'
import { services as diensten} from './diensten/ServicesComponent'
function Footer() {
  return (
    <footer className="content-info flex-none bg-ocean-950 py-8 md:py-12">
  <h2 id="footer-heading" className="sr-only">Footer</h2>
  <div className="container">
  <div className="mx-auto max-w-7xl pb-4 pt-16 sm:pt-24 lg:pt-16">
    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
      <div className="space-y-8 hidden footer-logo:block">
        <a href="/"><FooterLogo className="w-[240px]"/></a>
        <p className="text leading-6 text-gray-300">Jouw partner op de <strong>road to control</strong></p>
        <div className="flex space-x-6">
          <a href="https://www.linkedin.com/company/94142465/admin/dashboard/" className="text-gray-500 hover:text-gray-400">
            <span className="sr-only">LinkedIn</span>
            <LinkedInLogo/>
          </a>
          <a
            href="/contact"
            className="inline-block py-1 px-2 font-medium rounded hover:shadow text-white text-sm bg-ocean-600 hover:bg-ocean-700 flex items-center h-5"
          >
            Contact
          </a>
          <a href="tel:+31858200903" className="text-gray-200 text-sm py-2 -mt-2 px-3 inline-block hover:bg-ocean-950 rounded transition-colors">+31 (0)85 820 09 03</a>

        </div>
      </div>
      <div className="mt-6 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
        <div>
          <h3 className="text-sm font-semibold leading-6 text-white">Diensten</h3>
          <ul role="list" className="mt-6 space-y-2">
            {diensten.map((dienst) => (
              <li key={dienst.title}>
                <a href={dienst.ctaLink} className="text-sm leading-6 text-gray-300 hover:text-white">{dienst.footerTitle || dienst.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
           <h3 className="text-sm font-semibold leading-6 text-white"><a href="/" className="hover:text-white">Triple Control</a></h3>
          <ul role="list" className="mt-6 space-y-2">
            {navigation.map((item) => (
              <li key={item.name}>
                <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
          <ul role="list" className="mt-6 space-y-2">
            <li>
              <a href="/privacy" className="text-sm leading-6 text-gray-300 hover:text-white">Privacy policy</a>
            </li>
            <li>
              <a href="/algemene-voorwaarden" className="text-sm leading-6 text-gray-300 hover:text-white">Algemene voorwaarden</a>
            </li>
            <li>
              <a href="/responsible-disclosure" className="text-sm leading-6 text-gray-300 hover:text-white">Responsible disclosure</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
      <p className="text-xs leading-5 text-gray-400">&copy; 2019 - 2025 Triple Control / De Kwant Consultants B.V. Alle rechten voorbehouden.</p>
    </div>
  </div>
  </div>
    </footer>
  );
}

export default Footer;
